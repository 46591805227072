import DateRange from "./DateRange";
import {Selector} from "./Selector";
import {movingAvg, periodOptions} from "../data/options";


const Filter = props => {

    return (
        <div className="flex flex-wrap justify-end base-1/2 space-x-4 space-y-2">
            <div className="flex-initial ml-4 mt-2 max-h-12 min-w-64 border rounded-lg border-gray-300">
                <DateRange state={props.filters} setState={props.setFilters} labelName='Select Dates'/>
            </div>
            <div>
                <Selector options={periodOptions} state={props.filters} setState={props.setFilters} name='period' labelName='View by:'></Selector>
            </div>
            <div>
                <Selector options={movingAvg} state={props.filters} setState={props.setFilters} name='r_avg' labelName='Show Moving Avg Days:'></Selector>
            </div>
        </div>)
};

export {Filter};