import {ColChart} from "../LineChart";
import {useEffect, memo, useState} from "react";
import apiCall from "../call";
import {txCategoryFields} from "../../data/chartSchemas";
import PieChart from "../PieChart";
import {mapMultiGroupData, preparePieSum} from "../../utils/dataTransforms";
import {RadioButton} from "../Selector";
import Select from "react-select";

const CategorySales = memo(({setAlert, filters, activeTab}) => {
    const [txData, setTxData] = useState(txCategoryFields)
    const [selectedFilter, setSelectedFilter] = useState('Category')

    const [selectedOptions, setSelectedOptions] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        console.log('call CS1')
        if (!activeTab) return  // load only if your tab is active

        let endpoint = selectedFilter === 'Category' ?  'rpc/sp_master_category_sales' : 'rpc/sp_sub_category_sales'
        apiCall(setAlert, endpoint, filters).then(resp => {
            if (resp) {
                let dt = resp.data
                let units = mapMultiGroupData(dt, '_period', '_category_name', '_units_sold' )
                let revenue = mapMultiGroupData(dt, '_period', '_category_name', '_revenue')
                let mixUnits = preparePieSum(units.series, 'Units Sold')
                let mixRevenue = preparePieSum(revenue.series, 'Revenue')
                setTxData({units, revenue, mixUnits, mixRevenue})

                setOptions(units.series.map(x => ({ 'value':x.name, 'label': x.name })))
            }
        });
        console.log('call CS2')
    }, [filters.filter, selectedFilter, activeTab])

    useEffect(() => {
        setSelectedOptions([])
    }, [selectedFilter])

    const filtered = () => {
        if (selectedOptions.length === 0) return txData
        const selectedOptionsArray = selectedOptions.map(x => x.value)
        let selection = txCategoryFields
        selection.units.series = txData.units.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        selection.revenue.series = txData.revenue.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )

        selection.mixUnits.data = txData.mixUnits.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        selection.mixRevenue.data = txData.mixRevenue.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )
        return selection
    }

    const handleSelect = event => {
        setSelectedOptions(event)
    }

    if (!activeTab) return null
    console.log('rendering CS')
    return(
        <div className="flex flex-row space-x-4">
        <div className="flex-initial w-64 space-y-2">
            <p className="font-sans inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Select Data</p>
            <RadioButton options={['Category', 'Sub-Category']}
                         selected={selectedFilter}
                         setSelected={setSelectedFilter}/>
            <Select
                isMulti
                name="brands"
                options={options}
                value={selectedOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelect}
                closeMenuOnSelect={false}
                menuIsOpen={true}
            />
        </div>

        <div className="flex-1 min-w-0 grid grid-cols-2">
            <PieChart title={{text: 'Sales Mix (Units)'}}
                      data={filtered().mixUnits}/>
            <PieChart title={{text: 'Sales Mix (Revenue)'}}
                      data={filtered().mixRevenue}/>
            <ColChart title={{text: 'Unit Sales'}}
                      yAxisName='Count'
                      data={filtered().units}/>
            <ColChart title={{text: 'Revenue'}}
                      yAxisName='USD'
                      data={filtered().revenue}/>
        </div>
        </div>
    )
});

export default CategorySales