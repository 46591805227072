import Datepicker from "react-tailwindcss-datepicker";
import {useState} from "react";
import {useEffect} from "react";

const DateRange = (props) => {
    const subtractMonths = (date, months) => {
        date.setMonth(date.getMonth() - months);
        return date;
    };

    const [dtValue, setDtValue] = useState({
        startDate: new Date(),
        endDate: new Date()
    });

    useEffect(() => {
        let start = subtractMonths(new Date(), 3)
        let start_date = start.toISOString().split('T')[0]

        let end = new Date()
        let end_date = end.toISOString().split('T')[0]
        props.setState({...props.state, start_date, end_date})

        setDtValue({
            startDate: start,
            endDate: end
        })
    }, []);

    const handleDateChange = (val) => {
        setDtValue(val);
        props.state['start_date'] = val.startDate
        props.state['end_date'] = val.endDate
        props.setState(props.state)
    }

    return (
            <Datepicker value={dtValue}
                        onChange={handleDateChange}
                        placeholder={props.labelName}
                        showShortcuts={true}
                        showFooter={false}
                        startFrom={subtractMonths(new Date(), 3)}
                        inputClassName="relative transition-all duration-300 mt-1 py-2.5 pl-4 pr-14 w-full dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide text-sm placeholder-gray-800 bg-white disabled:opacity-40 disabled:cursor-not-allowed focus:outline-none"
                                   />
        )
}
//                         toggleClassName="flex-1 center"
export default DateRange