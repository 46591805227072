import {Routes, Route} from "react-router-dom";

import {HomePage} from "./HomePage";
import {Navigation} from "./Navigation";
import {StoresPage} from "./StoresPage";
import {OverviewPage} from "./OverviewPage";
import {StorePerformancePage} from "./StorePerformancePage";
import {InventoryPage} from "./InventoryPage";


function App() {
    console.log('render main')
    return (
        <body className="App">
            <Navigation>
                <Routes>
                    {/*<Route exact path="/" element={<HomePage/>}/>*/}
                    <Route exact path="overview" element={<OverviewPage/>}/>
                    {/*<Route path="stores-list" element={<StoresPage/>}/>*/}
                    <Route path="store-performance" element={<StorePerformancePage/>} />
                    {/*<Route path="inventory" element={<InventoryPage/>} />*/}
                </Routes>
            </Navigation>
    </body>
    );
}

export default App;
