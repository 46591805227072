import axios from "axios";
import {AlertTypes} from "../data/options";

const apiCall = (setAlert, endpoint, filters) => {
    console.log('Calling endpoint, filters', endpoint, filters)
    const nginx_url =  `${window.location.protocol}//api.${window.location.hostname}/api/${endpoint}`
    let token = sessionStorage.getItem('tk')
    let header = {headers: {'Authorization': 'Bearer ' + token}}
    return axios.post(nginx_url, filters, header).catch(error => {
            let alert = {type: AlertTypes.ERROR, msg: error.message, visible: true}
            setAlert(alert)
        });
}

export default apiCall