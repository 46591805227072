import {useEffect, useState} from "react";
import apiCall from "./call";
import {defaultTenantPlaceholder} from "../data/options";


function caps(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TenantSelector = props => {
    const [tenantMap, setTenantMap] = useState(defaultTenantPlaceholder )

    useEffect(() => {
        apiCall(props.setAlert, 'rpc/base_tenant_access', {}).then(resp => {
            if (resp) {
                if (props.allTenants) {
                    let allTenants = {
                        '_tenant_id': resp.data.map(x => x._tenant_id).join(),
                        '_company_name': 'All',
                        '_store_name': 'Stores'
                    }
                    resp.data.push(allTenants)
                }
                setTenantMap(resp.data);
            }
        })
    }, [])

    // puts default tenant into the filter state
    useEffect(() => {
        if  (props.allTenants && tenantMap !== defaultTenantPlaceholder)  {
            // setting company to all as a default selection option
            props.state[props.name] = tenantMap.filter((x) => x._company_name === 'All')[0]._tenant_id
            props.setState({...props.state})
        }
        else if (tenantMap !== defaultTenantPlaceholder) {
            props.state[props.name] = tenantMap[0]._tenant_id
            props.setState({...props.state})
        }

    }, [tenantMap])

    const prepName = obj => {
        return caps(obj._company_name).replace(/_/g, ' ') + ' – ' + caps(obj._store_name).replace(/_/g, ' ')
    }

    const handleClick = event => {
        if (tenantMap === defaultTenantPlaceholder) {
            apiCall(props.setAlert, 'rpc/base_tenant_access', {}).then(resp => {
                if (resp) {
                    if (props.allTenants) {
                        let allTenants = {
                            '_tenant_id': resp.data.map(x => x._tenant_id).join(),
                            '_company_name': 'All',
                            '_store_name': 'Stores'
                        }
                        resp.data.push(allTenants)
                    }
                    setTenantMap(resp.data);
                }
            })
        }
    }

    const handeChange = event => {
        event.preventDefault()
        props.state[props.name] = event.target.value
        props.setState({...props.state})
    }

    return (
        <select className="select select-ghost w-full max-w-xs focus:outline-none" onChange={handeChange} onClick={handleClick}>
            {tenantMap.map(opt => <option value={opt._tenant_id} selected={props.state[props.name] === opt._tenant_id ? true : null}>{prepName(opt)}</option>)}
        </select>
    )
}

const Selector = props => {
    const handeChange = event => {
        event.preventDefault()
        let value = event.target.value
        props.state[props.name] = value
        props.setState(props.state)
    }

    const prepareName = opt => {
        let option = typeof(opt) === 'string' ? caps(opt) : opt
        let label = props.labelName ? props.labelName + ' ' + option : option
        return label
    }

    return (
        <select className="select select-bordered max-w-xs focus:outline-none" onChange={handeChange}>
            {props.options.map(opt => <option value={opt} selected={props.state[props.name] === opt ? true : null}>{prepareName(opt)}</option>)}
        </select>
    )
}

const RadioButton = ({options, selected, setSelected}) => {
    const handleClick = event => {
        event.preventDefault()
        setSelected(event.target.value)
    }

    return (
        <div className="grid grid-flow-col gap-4 btn-group">
            {options.map(opt =>
                <button value={opt} className={selected === opt ? " btn btn-active" : "btn"} onClick={handleClick}>{opt}</button>
            )}
        </div>
    )
}

export {TenantSelector, Selector, RadioButton}