import {trendSchema} from "../../data/chartSchemas";

export const Stat = ({title, description, value, unit}) => {
    let trendDirection = value.trend?.direction ? '\u2197' : '\u2198'
    if (value.trend?.direction === null) trendDirection = ''

    let trendUnit = value.trend?.unit ? value.trend?.unit : ''
    let trendValue =  value.trend?.points + trendUnit + ' ' + trendDirection

    let trendStyle = value.trend?.direction ? 'stat-figure text-primary font-semibold text-green-400' : 'stat-figure text-primary font-semibold text-rose-400'
    return (
        <div className="stats stats-vertical lg:stats-horizontal shadow">

            <div className="stat">
                {value.trend && value.trend !== trendSchema ? <div className={trendStyle}>{trendValue}</div> : null}
                <div className="stat-title">{title}</div>
                <div className="stat-value">{value.main}
                    <span className={'text-lg'}>
                        {unit}
                    </span>
                </div>
                {description ? <div className="stat-desc">{description}</div> : null}
            </div>

        </div>
    )
}
