export const mapData = (dt, xAxis, colMap) => {
    const cols = Object.keys(colMap)
    const ser = cols.map(colName => ( {name: colName, data: dt.map( x => x[colMap[colName]] )} ))
    const xAixCats = dt.map(x => x[xAxis])
    const dataPointsCount = ser.reduce((sum, x) => sum + x.data.length, 0)
    return {
        xAxis: [{
            type: 'datetime',
            categories: [...new Set(xAixCats)]
        }],
        series: ser,
        count: dataPointsCount
    }
}

const emptyObject = (obj, excludeCol) => {
    // {a:10, b:20, c: 'foo'} => {a:0, b:0} while excluding property 'c'
    let objKeys = Object.keys(obj).filter((x) => x !==  excludeCol)
    objKeys.push('count')
    let cleanObject = Object.fromEntries(objKeys.map(k => [k, 0]))
    return cleanObject
}

export const getAvgGroupedByDimension = (data, dimension) => {
    // SELECT avg(c1), avg(c2), avg(c3) FROM data GROUP BY dimension
    let result = {}
    for (const row of data) {
        let period = row[dimension]
        if(!result[period]) result[period] = emptyObject(data[0], dimension)

        let props = Object.keys(data[0]).filter((x) => x !==  dimension)
        result[period].count += 1
        for (const prop of props) {
            result[period][prop] = (result[period][prop] + row[prop] ) / result[period].count
        }
    }
    return result
}

const getMaxKeyFrom = obj => {
    return Object.keys(obj).reduce(function (a, b) { return a > b ? a : b; })
}

const getMinKeyFrom = obj => {
    return Object.keys(obj).reduce(function (a, b) { return a < b ? a : b; });
}

export const extractStatsArrangedByPeriod = (storesAvg, dimension) => {
    // extract stats from the obj: {'2023-01-01: {sales:100},  '2023-02-01': {sales:200} }
    let currPeriodValue = storesAvg[getMaxKeyFrom(storesAvg)][dimension]
    let prevPeriodValue = storesAvg[getMinKeyFrom(storesAvg)][dimension]
    let trendChangePct = (currPeriodValue - prevPeriodValue) / prevPeriodValue * 100

    return {
        main: currPeriodValue.toFixed(2),
        trend: {
            points: trendChangePct.toFixed(2),
            direction: trendChangePct > 0
        }
    }
}

export const mapMultiGroupData = (dt, xAxis, groupColName, valueColName) => {
    let seriesMap = {}
    for (let row of dt) {
        if (row[groupColName] === null) {
            continue;
        }

        let groupName = row[groupColName] ? row[groupColName] : 'Undefined'
        let value = row[valueColName]
        seriesMap[groupName] ??= []
        seriesMap[groupName].push(value)
    }

    const groups = Object.keys(seriesMap)
    const sereisArr = groups.map(name => ( {name: name, data: seriesMap[name]} ))
    const xAixCats = [...new Set( dt.map(x => x[xAxis]) )]
    return {
        xAxis: [{
            categories: xAixCats
        }],
        series: sereisArr,
        count: xAixCats.length * sereisArr.length
    }
}

export const preparePieSum = (dataArr, dimensionName) => {
    const sum = arr => {return arr.reduce( (sum, a) => sum + a, 0 )}
    return {
        name: dimensionName,
        colorByPoint: true,
        data: dataArr.map(x => ( {name: x.name, y: Math.round(sum(x.data))} ))
    }
}

export const convertToArrMatrix = (data, dimensionNames) => {
    let series = []
    let counter = 0
    let xAxisMap = Object.assign( ...dimensionNames.map((key, index) => ( {[key]: index} )) )
    for (let row of data) {
        let rowArr = Object.keys(xAxisMap).map(x => ( [xAxisMap[x], counter, row[x]] ))
        series.push(...rowArr)
        counter++
    }
    return series
}

export const pivotMatrixByDay = (data, dimensionName, yAxisName) => {
    let series = []
    let yAxisArr = data[1].map(x => x[yAxisName])
    let hourGroupMap = Object.assign( ...yAxisArr.map((key, index) => ( {[key]: index} )) )

    for (const [dayNumber, arr] of Object.entries(data)) {
        for (let row of arr) {
            let xAxisVal = dayNumber - 1
            let yAxisVal = hourGroupMap[row._hourgroup]
            let cellVal = row[dimensionName]
            let newRow = [xAxisVal, yAxisVal, cellVal]
            series.push(newRow)
        }
    }

    return {
        xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        yAxis: yAxisArr,
        series: series
    }
}