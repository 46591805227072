import TabsContainer from "./Tabs";
import GrowthGraphs from "./dashboards/GrowthGraphs";
import CategorySales from "./dashboards/CategorySales";
import BrandSales from "./dashboards/BrandSales";
import PurchaseBehaviour from "./dashboards/PurchaseBehaviour";
import PeriodComparison from "./dashboards/PeriodComparison";
import {Filter} from "./Filter";
import Alert from "./Alert";
import {useState} from "react";
import {TenantSelector} from "./Selector";
import Staffing from "./dashboards/Staffing";
import {filterDefault, PageNames} from "../data/options";

export const StorePerformancePage = props => {
    const [lastTab, setLastTab] = useState(0)
    const [activeTab, setActiveTab] = useState([])

    const [alert, setAlert] = useState({type: 'alert', msg: '', visible: false})
    const [filters, setFilters] = useState(filterDefault)

    const handleFilterClick = (e) => {
        e.preventDefault()
        let tab = [lastTab]
        setActiveTab(tab);
        console.log('resetting active tab state to', tab);

        let filter = !filters.filter
        setFilters({...filters, filter})
        console.log('Filter Applied', filters)
    }

    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between mb-4">
                    <p className='font-sans pl-4 mt-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                        {PageNames.PERFORMANCE.name}
                    </p>
                    <Filter filters={filters} setFilters={setFilters}/>
            </div>
            <div className="flex flex-row items-center justify-between ">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} name='tenant_id'/>
                <button className="btn btn-sm w-full max-w-xs" onClick={handleFilterClick}>Filter</button>
            </div>
            {alert.visible ? <Alert alert={alert} setAlert={setAlert}/> : null}

            <TabsContainer activeTab={activeTab} setLastTab={setLastTab} setActiveTab={setActiveTab}>
                <GrowthGraphs name="Growth Graphs" activeTab={activeTab.includes(0)} setAlert={setAlert} filters={filters}/>
                <CategorySales name="Category Sales" activeTab={activeTab.includes(1)} setAlert={setAlert} filters={filters}/>
                <BrandSales name="Brand Sales" activeTab={activeTab.includes(2)} setAlert={setAlert} filters={filters}/>
                <PurchaseBehaviour name="Purchase Behaviour" activeTab={activeTab.includes(3)} setAlert={setAlert} filters={filters}/>
                <PeriodComparison name="Period Comparison" activeTab={activeTab.includes(4)} setAlert={setAlert} filters={filters}/>
                <Staffing name="Staffing" activeTab={activeTab.includes(5)} setAlert={setAlert} filters={filters}/>
            </TabsContainer>
        </div>
)}
